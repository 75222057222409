import { t } from "i18next";
import styles from "./signed-user.module.scss";
import { useEffect, useState, useRef } from "react";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import { Form, Spinner } from "react-bootstrap";
import moment from "moment";
import { useLocation } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import TGTextField from "../../../../../shared/tg-text-field";
import { blockSpecialChar, isvalidAlphabet } from "../../../../../utils/helper";
import { UpcomingTrip } from "./upcoming-trips";
import { PastTrip } from "./past-trips";
import isAuthenticate from "../../../../../utils/hook/isAuthenticate";
import {
  resetTripDetails,
  postAddTripPending,
} from "../../../slice/myTripsSlice";
import TGModal from "../../../../../shared/tg-modal";
import TGIcon from "../../../../../shared/tg-icon";
import TGButtonVariants from "../../../../../shared/tg-button-variants";
import { useNavigate } from "react-router-dom";
import { config } from "../../../../../config/global";

export const MyTripsSignedUser = () => {
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = isAuthenticate();
  const [isTicketNoError, setIsTicketNoError] = useState(false);
  const [ticketNo, setTicketNo] = useState("");
  const [isBookingNoError, setIsBookingNoError] = useState(false);
  const [disableButton, setDisable] = useState(true);
  const name = isAuthenticated
    ? sessionStorage.getItem("isROPLoggedIn") === "true"
      ? sessionStorage.getItem("FullName")
      : sessionStorage.getItem("lastName")
    : "";
  const prefilledInput =
    sessionStorage.getItem("isROPLoggedIn") === "true"
      ? name?.split(" ")[name?.split(" ").length - 1].toUpperCase()
      : name;
  const [lastName, setLastName] = useState(
    isAuthenticated ? prefilledInput : ""
  );
  const [isLastNameError, setIsLastNameError] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const [activeTab, setActiveTab] = useState("Upcoming Trips");
  const [maxLength, setMaxLength] = useState<any>();
  const [isAddMyTripSuccess, setIsAddMyTripSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [addMyTripError, setAddMyTripError] = useState("");
  const [selectedPnr, setSelectedPnr] = useState({});
  const [tripType, setTripType] = useState("Upcoming");
  const [scrollClass, setScrollClass] = useState(false);
  const bookingRegex = new RegExp(
    /^(?=.{6}$)(?![0-9]{6})(?![a-zA-Z]{6})[a-zA-Z0-9]{6}$/
  );
  const ticketRegex = new RegExp(/^[0-9]{13}$/);
  const nameRegex = new RegExp(/^(?!\s*$)[a-zA-Z\s]{2,}$/);

  const addTripResponse = useSelector(
    (state: RootState) => state?.osciReducer?.myTrips
  );
  const deleteTripsResponse = useSelector(
    (state: RootState) => state?.osciReducer?.deleteTripCard
  );

  useEffect(() => {
    if (addTripResponse.addTriperror == "") {
      setShowModal(false);
      setAddMyTripError("");
    } else {
      setAddMyTripError(addTripResponse.addTriperror);
      setShowModal(true);
    }
  }, [addTripResponse.addTriperror]);

  const handleManageBooking = (pnrData: any) => {
    const manageBookingUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.MANAGE_BOOKING_API;
    const lang = i18n?.language?.split("-")?.[0];
    const manageBookingQueryParams =
      "booking_no=" +
      pnrData.pnr +
      "&lastname=" +
      pnrData.lastName +
      "&lang=" +
      lang;

    const amadeusRedirectionUrl =
      manageBookingUrl + "?" + manageBookingQueryParams;
    window.open(amadeusRedirectionUrl, "_blank");
  };

  useEffect(() => {
    setLastName("");
    setTicketNo("");
    setDisable(true);
    if (addTripResponse?.isAddTripSuccess == true) {
      setShowToast(true);
      setShowDeleteToast(false);
      if (addTripResponse?.addMyTripResponse?.pastTrip == true) {
        setActiveTab("Past Trips");
      } else {
        setActiveTab("Upcoming Trips");
      }
    } else {
      setShowToast(false);
    }
    setTimeout(() => {
      setShowToast(false);
      setShowDeleteToast(false);
    }, 5000);
  }, [addTripResponse.isAddTripSuccess]);

  useEffect(() => {
    if (
      deleteTripsResponse.hasData == true &&
      deleteTripsResponse.isLoading == false
    ) {
      setShowToast(true);
      setShowDeleteToast(true);
    } else {
      setShowToast(false);
      setShowDeleteToast(false);
    }
    setTimeout(() => {
      setShowToast(false);
      setShowDeleteToast(false);
    }, 3000);
  }, [deleteTripsResponse.hasData]);

  useEffect(() => {
    if (isAuthenticated) {
      setLastName(lastName !== undefined ? lastName : prefilledInput);
      setIsLastNameError(true);
    }
  }, [ticketNo]);

  const handleBookingNum = (a: any, label: any) => {
    if (lastName !== "" && lastName !== undefined) {
      setDisable(false);
    }
    if (
      lastName === "" ||
      lastName === undefined ||
      a.target.value === "" ||
      a.target.value === undefined
    ) {
      setDisable(true);
    }
    if (blockSpecialChar(a.target.value)) {
      if (/^(0|[1-9][0-9]*)$/.test(ticketNo)) {
        setMaxLength(13);
      } else if (/^[a-zA-z0-9]{0,6}/.test(ticketNo)) {
        setMaxLength(6);
      }
      setTicketNo(a.target.value.toUpperCase());
      setIsBookingNoError(false);
      if (a.target.value.length > 0 && a.target.value != null) {
        setIsTicketNoError(true);
        setTicketNo(a.target.value.toUpperCase());
        handleChangeInput(a, label);
      } else {
        setIsTicketNoError(false);
      }
    }
  };

  const handleChangeInput = (newValue: any, label: any) => {
    //This conversion is used to convert the labels send from onChange function to lowerCamelCase format.
    const fieldLabel = label
      .replace(/[^\w\s-]/g, "")
      .split(/[\s-]+/)
      .map((word: any, index: any) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");
  };

  const handlelastName = (b: any, label: string) => {
    if (ticketNo !== "" && ticketNo !== undefined) {
      setDisable(false);
    }
    if (
      ticketNo === "" ||
      ticketNo === undefined ||
      b.target.value === "" ||
      b.target.value === undefined
    ) {
      setDisable(true);
    }
    if (isvalidAlphabet(b.target.value)) {
      setLastName(b.target.value);
      setIsNameError(false);
      if (b.target.value.length > 0 && b.target.value != null) {
        setIsLastNameError(true);
        setLastName(b.target.value.toUpperCase());
        handleChangeInput(b, label);
      } else {
        setIsLastNameError(false);
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddTrip = () => {
    let newError = {};
    if (!disableButton) {
      if (ticketNo.length === 6 || ticketNo.length === 13) {
        if (bookingRegex.test(ticketNo) || ticketRegex.test(ticketNo)) {
          newError = { ...newError, pnrError: false };
          if (nameRegex.test(lastName)) {
            newError = { ...newError, nameError: false };
          } else {
            setIsNameError(true);
            newError = { ...newError, nameError: true };
          }
        } else {
          setIsBookingNoError(true);
          newError = { ...newError, pnrError: true };
        }
      } else {
        setIsBookingNoError(true);
        newError = { ...newError, pnrError: true };
        if (nameRegex.test(lastName)) {
          newError = { ...newError, nameError: false };
        } else {
          setIsNameError(true);
          newError = { ...newError, nameError: true };
        }
      }
    } else {
    }
    let addTripPayload = { pnr: ticketNo, lastName: lastName };
    if (!disableButton && !newError.nameError && !newError.pnrError) {
      dispatch(postAddTripPending(addTripPayload));
    }
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const value = [
    {
      value: "Upcoming Trips",
      label: "Upcoming Trips",
      mobileLabel: "Upcoming Trips",
    },
    {
      value: "Past Trips",
      label: "Past Trips",
      mobileLabel: "Past Trips",
    },
  ];

  const handleTabActive = (data: string) => {
    dispatch(resetTripDetails());
    setActiveTab(data);
    setSelectedPnr({});
    if (data == "Upcoming Trips") {
      navigate(`/${i18n.language}/my-trips`);
    } else {
      navigate(`/${i18n.language}/my-trips/past-trips`);
    }
  };

  useEffect(() => {
    if (location.pathname.split("/")[3]?.includes("past-trips")) {
      setActiveTab("Past Trips");
    } else {
      setActiveTab("Upcoming Trips");
    }
  }, []);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrollClass(true);
    } else {
      setScrollClass(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleData = (data: any) => {
    setSelectedPnr(data);
  };

  const handleTripType = (data: string) => {
    setTripType(data);
  };

  const renderMultiCityAirportCode = (item: any) => {
    //contains flightRef of connecting flights
    const flight = [];
    //Contains segmentGroups Length
    const flightLength = [];
    let content = "";
    //Contains segmentGroups last flightRef
    let lastArray = [];

    //Iteration for to push elements in an flight array and flightLength array
    for (let i: number = 0; i < item?.segmentGroups.length; i++) {
      flightLength.push(
        JSON.parse(item?.segmentGroups[i].connectionSegments).length + 1
      );
      for (
        let j: number = 0;
        j < JSON.parse(item?.segmentGroups[i]?.connectionSegments).length;
        j++
      ) {
        flight.push(JSON.parse(item?.segmentGroups[i]?.connectionSegments)[j]);
      }
    }

    //Iteration for to push elements in an lastArray
    for (let last: number = 0; last < item?.segmentGroups.length; last++) {
      lastArray.push(
        JSON.parse(item?.segmentGroups[last].connectionSegments).reverse()[0]
      );
    }
    let startVal = 0;
    let count = 0;

    //condition for checking flightDetails flightRef number with flight array elements
    for (let k: number = 0; k < item?.flightDetails.length; k++) {
      if (Number(item?.flightDetails[k]?.flightRef) === flight[count]) {
        if (lastArray[startVal] === Number(item?.flightDetails[k]?.flightRef)) {
          if (
            item?.flightDetails[k]?.flightRef ===
            item?.flightDetails.length.toString()
          ) {
            content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode}`;
          } else {
            content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode} // `;
          }
          startVal++;
        } else {
          content += `${item?.flightDetails[k]?.departureCode}-`;
        }

        count++;
      } else {
        if (
          item?.flightDetails[k]?.flightRef ===
          item?.flightDetails.length.toString()
        ) {
          content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode}`;
        } else {
          content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode} // `;
        }
      }
    }
    return content;
  };
  return (
    <>
      {Object.keys(selectedPnr).length > 0 &&
        isDesktopView &&
        tripType === "Upcoming" && (
          <div
            className={
              scrollClass
                ? `${styles.headerContainer1} global-content-padding`
                : `${styles.headerContainer} global-content-padding`
            }
          >
            <div className={styles.textContainer}>
              <div className={styles.herderHeading}>
                {selectedPnr?.tripType == "ROUND"
                  ? t("label_book_widget_round_trip")
                  : selectedPnr?.tripType == "MULTI_CITY"
                  ? t("label_trip_multicity")
                  : t("label_book_widget_one_way")}
              </div>
              <div className={styles.herderSubHeading}>
                {selectedPnr?.tripType == "ROUND"
                  ? `${selectedPnr.flightDetails[0].origin} - ${selectedPnr.flightDetails[0].destination}`
                  : selectedPnr?.tripType === "MULTI_CITY"
                  ? renderMultiCityAirportCode(selectedPnr)
                  : `${selectedPnr.flightDetails[0].origin} - ${
                      selectedPnr.flightDetails[
                        selectedPnr.flightDetails.length - 1
                      ].destination
                    }`}
              </div>
              <div className={styles.herderSubHeading1}>
                {selectedPnr?.tripType === "ONE_WAY" &&
                  `${selectedPnr.flightDetails[0].arrivalAirportName} - ${selectedPnr.flightDetails[0].departureAirportName}`}
                {selectedPnr?.tripType === "ROUND" &&
                  `${selectedPnr.flightDetails[0].arrivalAirportName} - ${
                    selectedPnr.flightDetails[
                      selectedPnr.flightDetails.length - 1
                    ].arrivalAirportName
                  }`}
                {selectedPnr?.tripType === "MULTI_CITY" &&
                  //  renderMulticityAirportName(resp))}
                  `${selectedPnr?.flightDetails[0].arrivalAirportName} - ${
                    selectedPnr?.flightDetails[
                      selectedPnr?.flightDetails.length - 1
                    ].arrivalAirportName
                  }`}
              </div>
            </div>
            <div className={styles.buttonContainer}>
              {" "}
              <TGButtonVariants
                label={t("label_book_widget_manage_booking")}
                disabled={false}
                display="flex"
                fillColor=""
                size=""
                align="left"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                bgColor="#684B9B"
                padding="12px 16px"
                textColor="#FFF"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                bgColorHover="#684B9B"
                height=" 62px"
                width={isDesktopView ? "170px" : "100%"}
                onClick={() => handleManageBooking(selectedPnr)}
              />
            </div>
          </div>
        )}
      <div className={`${styles.topTripContainer}`}>
        {showToast && (
          <div className={styles.toast}>
            <TGIcon icon="toast-icon" fillColor="" size="" />
            {showDeleteToast ? t("delete_trip_card_toast") : t("trip_added")}
          </div>
        )}
         {Object.keys(selectedPnr).length == 0 && <div className={`${styles.tripContainer} global-content-padding`}>
          <div className={styles.tripFormContainer}>
            <div className={styles.tripFormContent}>
              <div className={styles.tripInputContainer}>
                <div className={styles.bookingRefFieldContainer}>
                  <div className={styles.addTripHeader}>
                    {t("label_add_trip")}
                  </div>
                  <div className={styles.bookingRefField}>
                    <TGTextField
                      label={t("label_manage_booking_reference")}
                      placeholderText={t("label_manage_booking_reference")}
                      type="text"
                      variant={true}
                      customInputClassName={styles["input-promotion-code"]}
                      onTextChange={(e, label) => handleBookingNum(e, label)}
                      value={ticketNo || ""}
                      maxlength={maxLength}
                      isError={isBookingNoError}
                      errorLabel={t("label_manage_example_error")}
                    />
                  </div>
                  <ul className={styles.customTextColor}>
                    <li
                      className={
                        isBookingNoError
                          ? styles.errorText
                          : styles.customTextColor
                      }
                    >
                      {t("label_manage_booking_example")}
                    </li>
                    <li
                      className={
                        isBookingNoError
                          ? styles.errorText
                          : styles.customTextColor
                      }
                    >
                      {t("label_manage_ticket_example")}
                    </li>
                  </ul>
                </div>
                <div className={styles.lastNameContainer}>
                  <div className={styles.lastNameField}>
                    <TGTextField
                      label={t("label_manage_last_name")}
                      placeholderText={t("label_manage_last_name")}
                      type="text"
                      variant={true}
                      customInputClassName={styles["input-promotion-code"]}
                      onTextChange={(e, label) => handlelastName(e, label)}
                      value={lastName || ""}
                      maxlength={40}
                      isError={isNameError}
                      errorLabel={t("label_manage_last_name_error")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.addTripButtonContainer}>
            <div
              className={`${styles.addTripButton} ${
                disableButton ? styles.disableButton : styles.enableButton
              }`}
              onClick={handleAddTrip}
            >
              <span className={styles.buttonText}>{t("label_add_trip")}</span>
            </div>
          </div>
        </div>}
        <div className={`${styles.tripTabContainer} global-content-padding`}>
          <div className={styles.tripTabContent}>
            <div className={styles.tripHeader}>
              <span className={styles.headerText}>
                {t("label_trip_header")}
              </span>
            </div>
            <div className={styles.tabContentHeader}>
              {value.map((data, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleTabActive(data.value)}
                    className={`${
                      activeTab === data.value && styles["mytrips-tab-active"]
                    }`}
                  >
                    {isDesktopView ? data.label : data.mobileLabel}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.tabContent}>
            {activeTab === "Upcoming Trips" && (
              <UpcomingTrip
                sendData={handleData}
                handleTripType={handleTripType}
              />
            )}
            {activeTab === "Past Trips" && (
              <PastTrip sendData={handleData} handleTripType={handleTripType} />
            )}
          </div>
        </div>
      </div>

      {showModal && (
        <TGModal
          show={showModal}
          handleClose={handleCloseModal}
          buttonLabel="Ok"
          customModalClass={styles.customModalClass}
          customOffCanvasClass={styles.customOffCanvasClass}
          centered={true}
          backdrop="static"
        >
          <div className={styles["tcModalFlex"]}>
            <div className={styles["tcModal"]}>
              <TGIcon fillColor={"none"} icon="illustration-icon" />
              <div className={styles["modalContent"]}>
                <span className={styles["modalHeading"]}>
                  {addMyTripError == "Trip already added"
                    ? t("info_booking_duplicate")
                    : t("info_booking_error")}
                </span>
                <span className={styles["modalDescription"]}>
                  {addMyTripError == "Trip already added"
                    ? t("booking_duplicate_message")
                    : t("booking_error_message")}
                </span>
              </div>
            </div>
            <div className={styles["modalButtonContainer"]}>
              <div>
                <TGButtonVariants
                  label={t("button_too_many_codes_ok")}
                  disabled={false}
                  display="flex"
                  fillColor=""
                  size=""
                  align="left"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColor="#684B9B"
                  padding="12px 16px"
                  textColor="#FFF"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  bgColorHover="#684B9B"
                  height="50px"
                  width={isDesktopView ? "170px" : "100%"}
                  onClick={handleCloseModal}
                />
              </div>
            </div>
          </div>
        </TGModal>
      )}
    </>
  );
};
