import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import styles from "./flight-details.module.scss";
import TGIcon from "../../../../../../shared/tg-icon";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import {
  myTripsPnrRequest,
  setEReceiptState,
} from "../../../../slice/myTripsSlice";
import { RootState } from "../../../../slice/RootReducer";
import style from "../upcoming-trips/upcoming-trip.module.scss";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import {
  getEReceiptDataRequest,
  resetEreceiptDataRequest,
} from "../../../../slice/eReceiptSlice";
import { config } from "../../../../../../config/global";
import Timer from "../../../../../../shared/tg-timer";
import { Modal, ModalBody, Spinner } from "react-bootstrap";

interface Props {
  flightDetails?: any;
  selectedTab?: string;
  requestData?: any;
  parentIndex?: any;
}

export const FlightDetails = (props: Props) => {
  const [parentIndex, setParentIndex] = useState<any>();
  const [showStop, setShowStop] = useState(false);
  const [showPassenger, setShowPassenger] = useState(false);
  const [checkin, setCheckIn] = useState(false); // need to remove after api integration;
  const [tab, setTab] = useState<any>();
  const [paxInfo, setPaxInfo] = useState<any>();
  const [showPassengerInfo, setShowPassengerInfo] = useState(false);
  const [flightDetailsList, setFlightDetailsList] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const flightDetailsResponse = useSelector(
    (state: RootState) => state?.osciReducer?.myTrips.pnrDetails
  );

  const { eReceiptBlob, error } = useSelector(
    (state: RootState) => state?.osciReducer?.eReceipt
  );

  const checkinResponse = useSelector(
    (state: RootState) => state?.osciReducer?.myTrips.checkinDetails
  );

  useEffect(() => {
    setParentIndex(props.parentIndex);
    dispatch(myTripsPnrRequest(props.requestData));
    // dispatch(myTripsCheckinRequest(props.requestData));
  }, [dispatch]);

  useEffect(() => {
    const serviceInfo = [
      {
        reference: null,
        meal: null,
        seatNo: null,
        baggage: null,
        wifi: null,
      },
    ];
    if (flightDetailsResponse?.segmentGroupList?.length == 0) {
      let paxInfoDetails = {
        ...flightDetailsResponse,
        paxInfo: flightDetailsResponse?.paxInfo?.map((item: any) => {
          if (item?.serviceInfo === null) {
            return { ...item, serviceInfo: serviceInfo };
          }
          return item;
        }),
      };
      setPaxInfo(paxInfoDetails?.paxInfo);
      setFlightDetailsList(
        flightDetailsResponse?.flightInformationList?.map((data: any) => ({
          ...data,
          showPassenger: false,
          showStop: false,
        }))
      );
    } else {
      let paxInfoDetails = {
        ...flightDetailsResponse,
        paxInfo: flightDetailsResponse?.paxInfo?.map((item: any) => {
          if (item?.serviceInfo === null) {
            return { ...item, serviceInfo: serviceInfo };
          }
          return item;
        }),
      };
      setPaxInfo(paxInfoDetails?.paxInfo);
      const mergedArray = flightDetailsResponse?.segmentGroupList?.map(
        (item: any) => {
          const indices = JSON.parse(item.connectionSegments);
          const noOfStop = indices.length - 1;
          const firstObject =
            flightDetailsResponse?.flightInformationList[indices[0] - 1];
          const secondObject =
            flightDetailsResponse?.flightInformationList[indices[noOfStop] - 1];
          const thirdObject =
            indices.length > 2
              ? flightDetailsResponse?.flightInformationList[noOfStop - 1]
              : {};
          let firstTripDurationsplit = firstObject.tripDuration.split(" ");
          let secondTripDurationSplit = secondObject.tripDuration.split(" ");
          let firstTotalMinutes =
            Number(firstTripDurationsplit[0]) * 60 +
            Number(firstTripDurationsplit[2]);
          let secondTotalMinutes =
            Number(secondTripDurationSplit[0]) * 60 +
            Number(secondTripDurationSplit[2]);
          let totalAddedMinutes = firstTotalMinutes + secondTotalMinutes;
          let totalHours = Math.floor(totalAddedMinutes / 60);
          let totalMinutes = totalAddedMinutes % 60;
          const totalDuration = `${totalHours} hr ${totalMinutes} min`;
          return {
            departureCode: firstObject.departureCode,
            arrivalCode: secondObject.arrivalCode,
            departureCity: firstObject.departureCity,
            arrivalCity: secondObject.arrivalCity,
            departureAirport: firstObject.departureAirport,
            arrivalAirport: secondObject.arrivalAirport,
            numberOfStops: noOfStop,
            departureDate: firstObject.departureDate,
            arrivalDate: secondObject.arrivalDate,
            departureTime: firstObject.departureTime,
            arrivalTime: secondObject.arrivalTime,
            bookingClass: secondObject.bookingClass,
            departureDateTimeUtc: firstObject.departureDateTimeUtc,
            tripDuration: totalDuration,
            arrivalDateTimeUtc: secondObject.arrivalDateTimeUtc,
            airCarrier: [firstObject.airCarrier, secondObject.airCarrier],
            flightNumber: secondObject.flightNumber,
            departureTerminal: firstObject.departureTerminal,
            arrivalTerminal: secondObject.arrivalTerminal,
            flightRef: indices,
            flightStatus: secondObject.flightStatus,
            flightLegInfo: secondObject.flightLegInfo,
            standardBaggageAllowance: secondObject.standardBaggageAllowance,
            stopArray:
              Object.keys(thirdObject).length > 0
                ? [firstObject, thirdObject, secondObject]
                : [firstObject, secondObject],
            showPassenger: false,
            showStop: false,
            flightLayover: [],
            layoverTime: [],
          };
        }
      );
      // after merging connection segment need to push the remaining flightlist to final array
      const flightRefInMergedArray = new Set(
        mergedArray?.flatMap((item: any) => item?.flightRef)
      );
      const elementsToAdd =
        flightDetailsResponse?.flightInformationList?.filter(
          (item: any) => !flightRefInMergedArray.has(Number(item?.flightRef))
        );
      const finalArray = mergedArray?.concat(elementsToAdd);
      // connection segments has unordered then need to sort so below fn needed
      const sortFlightList = finalArray?.sort((a: any, b: any) => {
        const firstValue = Array.isArray(a?.flightRef)
          ? Math.min(...a?.flightRef)
          : Number(a?.flightRef);
        const secondValue = Array.isArray(b?.flightRef)
          ? Math.min(...b?.flightRef)
          : Number(b?.flightRef);
        return firstValue - secondValue;
      });
      sortFlightList?.forEach((item: any) => {
        // item.flightLayover = [];
        if (item?.stopArray) {
          item?.stopArray.forEach((stopItem: any) => {
            item?.flightLayover.push(...stopItem?.flightLegInfo);
          });
        }
      });
      sortFlightList?.forEach((element: any) => {
        const fl = element?.flightLayover;
        // element.layoverTime = [];
        for (let i = 0; i < fl?.length - 1; i++) {
          let arrivalDateTime = moment(fl[i].arrivalDateTime, "DD-MM-YY HH:mm");
          let departureDateTime = moment(
            fl[i + 1].departureDateTime,
            "DD-MM-YY HH:mm"
          );
          let tripDifference = moment.duration(
            departureDateTime.diff(arrivalDateTime)
          );
          let hours = tripDifference.hours();
          let mins = tripDifference.minutes();
          let tripDuration = hours + " h " + mins + " min Layover";
          element.layoverTime.push(tripDuration);
        }
      });
      setFlightDetailsList(sortFlightList);
    }
  }, [flightDetailsResponse]);

  useEffect(() => {
    const checkInArray: any[] = [];
    checkinResponse?.data?.forEach((dataItem: any) => {
      dataItem?.journeyElements?.forEach((journeyItem: any) => {
        const idValue = journeyItem.id;
        if (idValue in checkinResponse?.dictionaries?.journeyElement) {
          const flightData = {
            ...dataItem,
            journeyItem: {
              id: idValue,
              ...checkinResponse?.dictionaries?.journeyElement[idValue],
            },
          };
          checkInArray.push(flightData);
        }
      });
    });
  }, [checkinResponse]);

  useEffect(() => {
    setTab(props?.selectedTab);
  }, [props.flightDetails]);

  const handleShowStop = (index: any, numberOfStops: any) => {
    if (numberOfStops !== 0) {
      const data = flightDetailsList?.map((item: any, i: any) => {
        return index === i ? { ...item, showStop: !item.showStop } : item;
      });
      setFlightDetailsList(data);
    }
  };

  const handleShowPassengerList = (index: any) => {
    const data = flightDetailsList?.map((item: any, i: any) => {
      return index === i
        ? { ...item, showPassenger: !item.showPassenger }
        : item;
    });
    setFlightDetailsList(data);
  };

  const handleDayConversion = (date: any) => {
    let dayConversion = moment(date, "DD-MMM-YY")
      .format("dddd")
      .substring(0, 3);
    return dayConversion;
  };

  const tripDurationConversion = (arrivalTime: any, departureTime: any) => {
    let tripDuration = moment
      .utc(moment(arrivalTime, "HHmm").diff(moment(departureTime, "HHmm")))
      .format("HH : mm");
    let splitDuration = tripDuration.split(":");
    splitDuration[0] = splitDuration[0].trim() + "h";
    splitDuration[1] = splitDuration[1] + "min";
    return splitDuration.join(" : ");
  };

  const handleEReceiptNavigation = (ereceiptRequest: any) => {
    dispatch(resetEreceiptDataRequest());
    window.scrollTo(0, 0);
    dispatch(setEReceiptState(ereceiptRequest));
    let eReceiptUrl =
      process.env.REACT_APP_TG_API_BASE_URL + config.E_RECEIPT_API;
    let eReceiptParams = `pnrOrTicketNo=${ereceiptRequest.ticketno}&firstName=${ereceiptRequest.firstName}&lastName=${ereceiptRequest.lastName}`;
    const printeReceiptnUrl = eReceiptUrl + "?" + eReceiptParams;
    dispatch(
      getEReceiptDataRequest({
        url: printeReceiptnUrl,
        profileInfo: {
          firstName: ereceiptRequest.firstName,
          lastName: ereceiptRequest.lastName,
          bookingRef: ereceiptRequest.bookingRef,
        },
      })
    );
  };

  useEffect(() => {
    if (eReceiptBlob) {
      navigate(`/${i18n.language}/e-Receipt/success`);
    } else if (error) {
      navigate(`/${i18n.language}/e-Receipt/error`);
    }
  }, [eReceiptBlob, error]);

  const handleCheckIn = () => {
    const checkinUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.CHECKIN_API;
    const checkinQueryParams =
      "pnr=" +
      props?.requestData?.pnr +
      "&lname=" +
      props?.requestData?.lastName;
    const amadeusRedirectionUrl = checkinUrl + "?" + checkinQueryParams;
    window.open(amadeusRedirectionUrl, "_blank");
  };

  if (!flightDetailsResponse?.hasOwnProperty("flightInformationList")) {
    return (
      <div>
        <Modal
          show={true}
          centered
          className={`${styles["flights-my-trips-loader-container"]} flights-my-trips-main-loading-spinner`}
        >
          <ModalBody
            style={{
              width: "max-content",
              margin: "auto",
              background: "unset",
              border: "unset",
            }}
          >
            <Spinner animation="border" variant="primary" /> <br />
          </ModalBody>
        </Modal>
      </div>
    );
  } else {
    return (
      <>
        {flightDetailsList?.map((respData: any, index: any) => (
          <div className={style.flightDetailsContainer}>
            <div className={styles.departureHeader}>
              <div className={styles.departureDate}>
                <div className={styles["departure-date-content"]}>
                  <div className={styles["departure-date-header"]}>
                    <span className={styles["departure-date-text"]}>
                      {flightDetailsResponse?.tripType === "ROUND" &&
                        (index === 0
                          ? t("label_my_trips_departure")
                          : t("label_my_trips_return"))}
                      {`${handleDayConversion(
                        respData?.departureDate
                      )}${", "} ${moment(
                        respData?.departureDate,
                        "DD-MMM-YYYY"
                      ).format("DD MMM YYYY")}`}
                    </span>
                    {tab !== "PT" && (
                      // need to remove width and justify content for below container afer checkin api integration
                      <div className={styles["departure-schedule-container"]}>
                        <div className={styles.point}>
                          {/* need to uncomment below after checkin api integration */}
                          {/* <TGIcon icon="scheduled-point" size={""} fillColor={"#006723"} />
                                                <span className={styles.scheduledText}>{t("label_my_trips_scheduled")}</span> */}
                          -
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles["trip-duration-content"]}>
                    <span className={styles.tripDurationText}>
                      {t("label_my_trips_trip_duration")}
                      {respData?.tripDuration !== null &&
                      respData?.tripDuration !== ""
                        ? respData?.tripDuration
                        : tripDurationConversion(
                            respData?.arrivalTime,
                            respData?.departureTime
                          )}
                    </span>
                    {respData.numberOfStops != null &&
                      respData.numberOfStops !== 0 && (
                        <div className={styles.stopContainer}>
                          <TGIcon
                            icon="stop-point"
                            size={""}
                            fillColor={"#FF5C7C"}
                          />
                          <span className={styles.stopText}>
                            {respData.numberOfStops}{" "}
                            {t("label_redemption_stop")}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {tab !== "PT" && (
                <div className={styles.checkinContainer}>
                  <Timer
                    flightData={[respData]}
                    view={() => {}}
                    checkin={handleCheckIn}
                    isTimer={false}
                    page="my-trips"
                    isMyTrips={true}
                  />
                </div>
              )}
            </div>
            <div className={styles.departureContent}>
              <div className={styles["departure-detail"]}>
                <div className={styles["departure-detail-contents"]}>
                  <div className={styles["departure-origin-dest-containers"]}>
                    <div
                      className={
                        styles["departure-origin-dest-containers-single"]
                      }
                    >
                      <div className={styles["time-content"]}>
                        <span className={styles.timeText}>
                          {respData?.departureTime}
                        </span>
                      </div>
                      <div className={styles["city-content"]}>
                        <span className={styles["city-code"]}>
                          {respData?.departureCode}
                        </span>
                        <span className={styles["city-name"]}>
                          {respData?.departureCity}
                        </span>
                      </div>
                      <div className={styles["airport-container"]}>
                        <span className={styles["airport-name-text"]}>
                          {respData?.departureAirport}
                        </span>
                        <span className={styles["airport-terminal-text"]}>
                          {t("label_redemption_termainal")}{" "}
                          {respData?.departureTerminal != null &&
                          respData?.departureTerminal !== ""
                            ? respData?.departureTerminal
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className={styles["duration-container"]}>
                      <div className={styles["hour-container"]}>
                        <span className={styles.hourText}>
                          {respData?.tripDuration !== null &&
                          respData?.tripDuration !== ""
                            ? respData?.tripDuration
                            : tripDurationConversion(
                                respData?.arrivalTime,
                                respData?.departureTime
                              )}
                        </span>
                      </div>
                      <div className={styles["flight-icon-container"]}>
                        <span className={styles["flight-line"]}></span>
                        <TGIcon icon="flight-icon" size={""} fillColor={""} />
                        <span className={styles["flight-line"]}></span>
                      </div>
                      <div
                        className={styles["stop-container"]}
                        style={{
                          cursor:
                            respData?.numberOfStops !== null &&
                            respData.numberOfStops !== 0
                              ? "pointer"
                              : "auto",
                        }}
                        onClick={() =>
                          handleShowStop(index, respData.numberOfStops)
                        }
                      >
                        <span className={styles["stop-text"]}>
                          {respData?.numberOfStops !== null &&
                          respData.numberOfStops !== 0
                            ? respData?.numberOfStops +
                              " " +
                              t("label_redemption_stop")
                            : t("label_nonstop")}
                        </span>
                        {!respData.showStop &&
                          respData?.numberOfStops !== null &&
                          respData.numberOfStops !== 0 && (
                            <TGIcon
                              icon="stop-arrow"
                              size={""}
                              fillColor={""}
                            />
                          )}
                        {respData.showStop &&
                          respData?.numberOfStops !== null &&
                          respData.numberOfStops !== 0 && (
                            <TGIcon
                              icon="trip-downarrow-icon"
                              size={""}
                              fillColor={""}
                            />
                          )}
                      </div>
                    </div>
                    <div
                      className={
                        styles["departure-origin-dest-containers-single"]
                      }
                    >
                      <div className={styles["time-content"]}>
                        <span className={styles.timeText}>
                          {respData?.arrivalTime}
                        </span>
                        {respData?.numberOfStops !== null &&
                          respData?.numberOfStops !== 0 && (
                            <span className={styles.timeStopText}>
                              +{respData?.numberOfStops}
                            </span>
                          )}
                      </div>
                      <div className={styles["city-content"]}>
                        <span className={styles["city-code"]}>
                          {respData?.arrivalCode}
                        </span>
                        <span className={styles["city-name"]}>
                          {respData?.arrivalCity}
                        </span>
                      </div>
                      <div className={styles["airport-container"]}>
                        <span className={styles["airport-name-text"]}>
                          {respData?.arrivalAirport}
                        </span>
                        <span className={styles["airport-terminal-text"]}>
                          {t("label_redemption_termainal")}{" "}
                          {respData?.arrivalTerminal != null &&
                          respData?.arrivalTerminal !== ""
                            ? respData?.arrivalTerminal
                            : "--"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles["departure-origin-dest-class"]}>
                    <div>
                      <div className={styles["icon-container"]}>
                        <div className={styles["thai-logo-container"]}>
                          <TGIcon
                            icon="star-alliance-icon"
                            fillColor=""
                            size=""
                          />
                          <TGIcon icon="thai-text" fillColor="" size="" />
                        </div>
                        <span className={styles.tgFlightNum}>
                          {respData?.flightNumber}
                        </span>
                      </div>
                      <div>
                        {respData?.numberOfStops > 0 &&
                          respData?.airCarrier?.map(
                            (aircarrierData: any, airIndex: any) => (
                              <>
                                <div className={styles["airbus-container"]}>
                                  <span className={styles.airbusText}>
                                    {aircarrierData}
                                  </span>
                                </div>
                              </>
                            )
                          )}
                        {(respData?.numberOfStops == 0 ||
                          respData?.numberOfStops == null) && (
                          <>
                            <div className={styles["airbus-container"]}>
                              <span className={styles.airbusText}>
                                {respData?.airCarrier}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      {!respData.showStop && (
                        <span className={styles.classText}>
                          {respData?.bookingClass}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {respData.showStop && (
                <div className={styles["stop-flight-detail-container"]}>
                  <span className={styles["stop-flight-details-header-text"]}>
                    {t("label_redemption_flight_details")}
                  </span>
                  {respData?.stopArray?.map(
                    (stopStartData: any, stopArrayIndex: any) => (
                      <>
                        <div className={styles["flight-stop-start-container"]}>
                          <div className={styles["detail-container"]}>
                            <div className={styles["travel-line-container"]}>
                              <TGIcon icon="travel-icon" fillColor="" />
                            </div>
                            <div
                              className={styles["stop-flight-detail-content"]}
                            >
                              <div
                                className={
                                  styles["stop-time-city-airport-container"]
                                }
                              >
                                <div
                                  className={styles["stop-time-city-container"]}
                                >
                                  <div className={styles["time-content"]}>
                                    <span className={styles.timeText}>
                                      {stopStartData.departureTime}
                                    </span>
                                  </div>
                                  <span
                                    className={styles["departure-date-text"]}
                                  >
                                    {stopStartData.departureCity}{" "}
                                    {`(${stopStartData.departureCode})`}
                                  </span>
                                </div>
                                <div
                                  className={
                                    styles["stop-airport-name-container"]
                                  }
                                >
                                  <span className={styles["airport-name-text"]}>
                                    {stopStartData.departureAirport}
                                  </span>
                                  <TGIcon icon="circle-icon" fillColor="" />
                                  <span
                                    className={styles["airport-terminal-text"]}
                                  >
                                    {t("label_redemption_termainal")}{" "}
                                    {stopStartData.departureTerminal !== "" &&
                                    stopStartData.departureTerminal !== null
                                      ? stopStartData.departureTerminal
                                      : "--"}
                                  </span>
                                </div>
                              </div>
                              <span className={styles.airbusText}>
                                {stopStartData.tripDuration}
                              </span>
                              <div
                                className={
                                  styles["stop-time-city-airport-container"]
                                }
                              >
                                <div
                                  className={styles["stop-time-city-container"]}
                                >
                                  <div className={styles["time-content"]}>
                                    <span className={styles.timeText}>
                                      {stopStartData.arrivalTime}
                                    </span>
                                    {respData?.numberOfStops ===
                                      stopArrayIndex && (
                                      <span className={styles.timeStopText}>
                                        +{respData?.numberOfStops}
                                      </span>
                                    )}
                                  </div>
                                  <span
                                    className={styles["departure-date-text"]}
                                  >
                                    {stopStartData.arrivalCity}{" "}
                                    {`(${stopStartData.arrivalCode})`}
                                  </span>
                                </div>

                                <div
                                  className={
                                    styles["stop-airport-name-container"]
                                  }
                                >
                                  <span className={styles["airport-name-text"]}>
                                    {stopStartData.arrivalAirport}
                                  </span>
                                  <TGIcon icon="circle-icon" fillColor="" />
                                  <span
                                    className={styles["airport-terminal-text"]}
                                  >
                                    {t("label_redemption_termainal")}{" "}
                                    {stopStartData.arrivalTerminal !== "" &&
                                    stopStartData.arrivalTerminal !== null
                                      ? stopStartData.arrivalTerminal
                                      : "--"}
                                  </span>
                                </div>
                              </div>
                              <div className={styles["stop-airbus-container"]}>
                                <div className={styles["stop-airbus-content"]}>
                                  <span className={styles.hourText}>
                                    {stopStartData.flightLegInfo[0].flightOwner
                                      ?.split(" ")
                                      .slice(2)
                                      .join(" ")}
                                  </span>
                                  <TGIcon icon="circle-icon" fillColor="" />
                                  <span className={styles.hourText}>
                                    {stopStartData.flightNumber}
                                  </span>
                                </div>
                                <span className={styles.stopAirbusText}>
                                  {stopStartData.airCarrier}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={styles["passenger-class-container"]}>
                            <span className={styles.tgFlightNum}>
                              {stopStartData.bookingClass}
                            </span>
                          </div>
                        </div>
                        {respData?.numberOfStops !== stopArrayIndex &&
                          stopStartData?.flightLegInfo?.map(
                            (flightLayoverData: any, flIndex: any) => (
                              <div
                                className={styles["flight-stop-over-container"]}
                              >
                                <span className={styles.stopOverText}>
                                  {respData?.flightLayover?.length > 0
                                    ? `${
                                        flIndex == 0
                                          ? respData?.layoverTime[0]
                                          : respData?.layoverTime[1]
                                      } ${" "} ${stopStartData?.arrivalAirport}`
                                    : "-"}
                                </span>
                              </div>
                            )
                          )}
                      </>
                    )
                  )}
                </div>
              )}
            </div>
            <div className={styles.passengerPreferenceContainer}>
              <div className={styles["passenger-header-content"]}>
                <span className={styles["departure-date-text"]}>
                  {t("label_my_trips_passengers")}
                </span>
                {paxInfo.length > 1 && (
                  <div className={styles["show-passenger-button-container"]}>
                    <div
                      className={styles["show-passenger-button-content"]}
                      onClick={() => handleShowPassengerList(index)}
                    >
                      <div className={styles["passenger-button"]}>
                        <span className={styles.showPassengerText}>
                          {!respData.showPassenger
                            ? t("label_my_trips_additonal_passengers").split(
                                "[number]"
                              )[0] +
                              `${paxInfo.length - 1}` +
                              t("label_my_trips_additonal_passengers").split(
                                "[number]"
                              )[1]
                            : t(
                                "label_my_trips_additonal_hide_passengers"
                              ).split("[number]")[0] +
                              `${paxInfo.length - 1}` +
                              t(
                                "label_my_trips_additonal_hide_passengers"
                              ).split("[number]")[1]}
                        </span>
                        {!respData.showPassenger && (
                          <TGIcon icon="stop-arrow" fillColor="" />
                        )}
                        {respData.showPassenger && (
                          <TGIcon icon="trip-downarrow-icon" fillColor="" />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles["passenger-info-container"]}>
                {paxInfo?.map((paxData: any, paxIndex: any) => (
                  <div
                    style={{
                      display:
                        respData.showPassenger || paxIndex == 0
                          ? "flex"
                          : "none",
                      flexDirection: "column",
                    }}
                    id={index}
                  >
                    <div className={styles["passenger-info-container-sub"]}>
                      <div
                        className={styles["passenger-checkin-container-main"]}
                      >
                        <div>
                          {tab !== "PT" && (
                            // need to remove width after checkin integration for below container
                            <div
                              className={styles["passenger-checkin-container"]}
                            >
                              <div
                                className={
                                  styles["passenger-checkin-button-background"]
                                }
                                style={{
                                  backgroundColor: checkin
                                    ? "#55CD7D"
                                    : "#dfe0eb",
                                }}
                              >
                                {checkin && (
                                  <TGIcon
                                    icon="my-trip-check-in"
                                    fillColor=""
                                  ></TGIcon>
                                )}
                                <span className={styles.passengerCheckInText}>
                                  {" "}
                                  -{" "}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <div
                            className={styles.classText}
                            style={{ alignSelf: "stretch" }}
                          >
                            {`${
                              paxData.firstName !== null &&
                              paxData.firstName !== undefined
                                ? paxData.firstName
                                : ""
                            } 
                                                      ${
                                                        paxData.lastName !==
                                                          null &&
                                                        paxData.lastName !==
                                                          undefined
                                                          ? paxData.lastName
                                                          : ""
                                                      } 
                                                      ${
                                                        paxData.title !==
                                                          null &&
                                                        paxData.title !==
                                                          undefined
                                                          ? paxData.title
                                                          : ""
                                                      }`}
                          </div>
                          <div
                            className={styles.hourText}
                            style={{ alignSelf: "stretch" }}
                          >
                            {t("label_ticket_number")}:{" "}
                            {paxData?.ticketno?.replace(/-/g, "")}
                            {"  "}
                            {tab == "PT" && (
                              <span
                                className={styles.eReceiptText}
                                onClick={() =>
                                  handleEReceiptNavigation(paxData)
                                }
                              >
                                {t("label_my_trips_ereceipt")}{" "}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={styles["seat-container-main"]}>
                        <div className={styles["seat-content"]}>
                          <TGIcon icon="my-trip-seat-icon" fillColor="" />
                          <span className={styles.hourText}>
                            {paxData?.serviceInfo[0]?.seatNo != null &&
                            paxData?.serviceInfo[0]?.seatNo !== ""
                              ? paxData?.serviceInfo[0]?.seatNo
                              : tab == "PT"
                              ? t("label_my_trips_not_selected")
                              : " - "}
                          </span>
                        </div>
                        <div className={styles["seat-content"]}>
                          <TGIcon icon="my-trip-baggage-icon" fillColor="" />
                          <span className={styles.baggageText}>
                            {paxData?.serviceInfo[0]?.baggage != null &&
                            paxData?.serviceInfo[0]?.baggage !== ""
                              ? tab == "PT"
                                ? paxData?.serviceInfo[0]?.baggage +
                                  t("label_my_trips_baggage")
                                : paxData?.serviceInfo[0]?.baggage +
                                  "(Standard)"
                              : t("label_my_trips_not_selected")}
                          </span>
                        </div>
                        <div className={styles["seat-content"]}>
                          <TGIcon icon="my-trip-cutlery-icon" fillColor="" />
                          <span className={styles.cutleryText}>
                            {paxData?.serviceInfo[0]?.meal !== null &&
                            paxData?.serviceInfo[0]?.meal !== ""
                              ? paxData?.serviceInfo[0]?.meal
                              : t("label_my_trips_not_selected")}
                          </span>
                        </div>
                      </div>
                    </div>
                    {respData.showPassenger &&
                      paxInfo.length - 1 !== paxIndex && (
                        <div className={styles["passenger-border"]}></div>
                      )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
};
